import React from 'react';
import PageBuilder from '../PageBuilder';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import qs from 'query-string';

export default function PagePreview(props) {
  const params = qs.parse(props.location.search);
  const [pageData, setPageData] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setPageData(await JStorage.fetchOneDocument('page', {id: params.id}));
      } catch (ex) {
        console.warn('PagePreview ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [params.id]);

  if (!pageData) {
    return null;
  }

  return <PageBuilder {...props} pageContext={pageData} />;
}
